<!-- 常用运动员信息编辑 -->
<template>
  <el-dialog
    class="common-applicants__edit"
    :title="info[type].title"
    :before-close="handleClose"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      ref="formRef"
      v-loading="loading"
      :model="formData"
      label-width="100px"
      size="small"
      :rules="rules"
    >
      <el-form-item label="姓名" prop="name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="证件类型" prop="cardId">
        <el-input
          :placeholder="cardIdInputPlaceholder"
          v-model="formData.cardId"
          class="input-with-select"
        >
          <el-select
            v-if="certificateList.length > 1"
            v-model="formData.certificate"
            slot="prepend"
            placeholder="请选择证件类型"
            style="width: 100px"
            disabled
            @change="validateIdcard"
          >
            <el-option
              v-for="(item, index) in certificateList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="证件号码" prop="cardId">
        <el-input v-model="formData.cardId"></el-input>
      </el-form-item>-->
      <el-form-item label="出生日期" prop="birth">
        <el-date-picker
          type="date"
          placeholder="自动识别"
          value-format="yyyy-MM-dd"
          v-model="formData.birth"
          style="width: 100%;"
          :disabled="formData.certificate === '身份证'"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group
          v-model="formData.sex"
          :disabled="formData.certificate === '身份证'"
        >
          <el-radio label="男"></el-radio>
          <el-radio label="女"></el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="竞赛组别" prop="group">
        <el-select
          v-model="formData.group"
          placeholder="请选择竞赛组别"
          style="width: 100%"
        >
          <el-option label="高中组" value="高中组"></el-option>
          <el-option label="初中组" value="初中组"></el-option>
          <el-option label="小学甲组" value="小学甲组"></el-option>
          <el-option label="小学乙组" value="小学乙组"></el-option>
          <el-option label="幼儿园组" value="幼儿园组"></el-option>
        </el-select>
      </el-form-item> -->

      <!-- <el-form-item label="纪念服尺寸" prop="size">
        <el-select v-model="formData.size" placeholder="请选择纪念服尺寸" style="width: 100%">
          <el-option label="小码" value="小码"></el-option>
          <el-option label="中码" value="中码"></el-option>
          <el-option label="大码" value="大码"></el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item>
        <el-button
          :disabled="canNotClick"
          type="primary"
          @click="handleUpdate"
          v-if="type === 'edit'"
          >修改</el-button
        >
        <el-button
          :disabled="canNotClick"
          type="primary"
          @click="handleAdd"
          v-else
          >提交</el-button
        >
        <el-button @click="visible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { addMember, addMemberAdmin, putMember, putMemberAdmin } from "@/api";
import {
  returnGroupZhByBirth,
  returnTeamGroupZhByBirth,
  returnTuishouGroupZhByBirth
} from "@/utils/utils";
export default {
  props: {},
  data() {
    const validateIdcard = (rule, value, callback) => {
      if (this.formData.certificate === "身份证") {
        const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

        if (!this.checkIDCard(value)) {
          // if (reg.test(value) === false) {
          // 当不要验证身份证号码的时候，用这条
          callback(new Error("请填写正确的身份证号码。"));
        } else {
          const date = new Date();
          const nyear = date.getFullYear();
          const nmonth = date.getMonth() + 1;
          const nday = date.getDate();
          const year = value.slice(6, 10);
          const month = value.slice(10, 12);
          const day = value.slice(12, 14);
          const nd = new Date(`${nyear}/${nmonth}/${nday} 0:0:0`);
          const od = new Date(`${year}/${month}/${day} 0:0:0`);
          if (od < nd) {
            callback();
          } else {
            callback(new Error("出生日期必须小于报名日期。"));
          }
        }
        if (!this.checkIDCard(value)) {
          callback(new Error("请填写正确的身份证号码。"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      loading: false,
      visible: false,
      type: "add",
      info: {
        add: {
          title: "新增运动员信息"
        },
        edit: {
          title: "编辑运动员信息"
        }
      },
      formData: {
        name: "",
        certificate: "身份证", // 证件类型
        cardId: "",
        birth: "",
        sex: "",
        size: "",
        group: "",
        kickboxingGroup: "",
        height: "",
        weight: "",
        learningCycle: "" // 学习周期
      },
      rules: {
        name: [
          { required: true, message: "请输入运动员姓名", trigger: "blur" }
        ],
        certificate: [
          { required: true, message: "请选择证件类型", trigger: "blur" }
        ],
        cardId: [
          // { required: true, message: "请输入证件号码", trigger: "blur" } // 不做身份校验
          { validator: validateIdcard, trigger: ["blur", "change"] } // 做身份校验
        ],
        birth: [
          {
            required: true,
            message: "请输入出生年月",
            trigger: ["blur", "change"]
          }
        ],
        sex: [{ required: true, message: "请输入性别", trigger: "blur" }],
        size: [
          { required: true, message: "请输入纪念服尺寸", trigger: "blur" }
        ],
        group: [
          {
            required: true,
            message: "请输入竞赛组别",
            trigger: ["blur", "change"]
          }
        ]
      },
      // 允许提交或修改
      canNotClick: false,
      certificateList: [
        { label: "身份证", value: "身份证" }
        // { label: "护照", value: "护照" },
        // { label: "港澳回乡证", value: "港澳回乡证" },
        // { label: "台湾回乡证", value: "台湾回乡证" },
        // { label: "出生证", value: "出生证" },
        // { label: "其他", value: "其他" }
      ]
    };
  },
  computed: {
    cardIdInputPlaceholder() {
      if (this.certificateList.length === 1) {
        return `请输入${this.certificateList[0].label}号码`;
      } else {
        return "请输入证件号码";
      }
    }
  },
  created() {},
  mounted() {},
  watch: {
    "formData.cardId"(value) {
      if (
        this.formData.certificate === "身份证" &&
        this.formData.cardId.length === 18
      ) {
        this.formData.group = "";
        this.formData.kickboxingGroup = "";
        this.canNotClick = false;
        let year = value.slice(6, 10);
        let month = value.slice(10, 12);
        let day = value.slice(12, 14);
        let sex = value.slice(16, 17) % 2 === 0 ? "女" : "男";
        this.formData.birth = `${year}-${month}-${day}`;
        this.formData.sex = sex;
        if (year && month && day) {
          this.formData.group = returnGroupZhByBirth(this.formData.birth, sex);
          // this.formData.kickboxingGroup = returnSandaGroupZhByBirth(
          //   this.formData.birth
          // );
          this.formData.teamGroup = returnTeamGroupZhByBirth(
            this.formData.birth
          );
          this.formData.tuishouGroup = returnTuishouGroupZhByBirth(
            this.formData.birth
          );
        }
      } else {
        this.formData.group = "";
      }
    }
  },
  methods: {
    // 函数参数必须是字符串，因为二代身份证号码是十八位，而在javascript中，十八位的数值会超出计算范围，造成不精确的结果，导致最后两位和计算的值不一致，从而该函数出现错误。
    // 详情查看javascript的数值范围
    checkIDCard(idcode) {
      // 加权因子
      let weightFactor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      // 校验码
      let checkCode = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];

      let code = String(idcode);
      let last = idcode[17]; //最后一位

      let seventeen = code.substring(0, 17);

      // ISO 7064:1983.MOD 11-2
      // 判断最后一位校验码是否正确
      let arr = seventeen.split("");
      let len = arr.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        num = num + arr[i] * weightFactor[i];
      }

      // 获取余数
      let resisue = num % 11;
      let lastNo = checkCode[resisue];

      // 格式的正则
      // 正则思路
      /*
    第一位不可能是0
    第二位到第六位可以是0-9
    第七位到第十位是年份，所以七八位为19或者20
    十一位和十二位是月份，这两位是01-12之间的数值
    十三位和十四位是日期，是从01-31之间的数值
    十五，十六，十七都是数字0-9
    十八位可能是数字0-9，也可能是X
    */
      let idcardPatter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

      // 判断格式是否正确
      let format = idcardPatter.test(idcode);

      // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
      return last === lastNo && format ? true : false;
    },
    // 显示弹窗
    show(info) {
      if (info) {
        this.type = "edit";
        this.formData.id = info.id;
        this.formData.name = info.name;
        this.formData.certificate = info.certificate;
        this.formData.cardId = info.cardId;
        this.formData.birth = info.birth;
        this.formData.sex = info.sex;
        this.formData.size = info.size;
        this.formData.group = info.group;
        this.formData.teamGroup = info.teamGroup;
        this.formData.tuishouGroup = info.tuishouGroup;
      } else {
        this.type = "add";
        this.formData.name = "";
        this.formData.certificate = "身份证";
        this.formData.cardId = "";
        this.formData.birth = "";
        this.formData.sex = "男";
        this.formData.group = "";
        this.formData.size = "";
        this.formData.teamGroup = "";
        this.formData.tuishouGroup = "";
      }
      this.$nextTick(() => {
        if (this.$refs.formRef) {
          this.$refs.formRef.clearValidate();
        }

        this.visible = true;
      });
    },
    // 提交
    handleAdd() {
      let _this = this;
      _this.formData.userId = this.$store.state.user.id;
      _this.$refs.formRef.validate(validate => {
        if (validate) {
          _this.loading = true;

          // 提交/修改完成
          if (this.$store.state.user.authority === 0) {
            // 用户
            addMember(_this.formData)
              .then(res => {
                _this.loading = false;
                if (res.data.code === 200) {
                  _this.$alert("添加成功", "标题名称", {
                    confirmButtonText: "确定",
                    callback: action => {
                      _this.visible = false;
                      _this.$emit("getList");
                    }
                  });
                } else {
                  _this.$alert(res.data.message || "添加失败", "系统提示", {
                    confirmButtonText: "确定",
                    callback: action => {}
                  });
                }
              })
              .catch(err => {
                _this.loading = false;
                _this.$alert(err, "系统提示", {
                  confirmButtonText: "确定",
                  callback: action => {}
                });
              });
          } else {
            // 管理员
            addMemberAdmin(_this.formData)
              .then(res => {
                _this.loading = false;
                if (res.data.code === 200) {
                  _this.$alert("添加成功", "标题名称", {
                    confirmButtonText: "确定",
                    callback: action => {
                      _this.visible = false;
                      _this.$emit("getList");
                    }
                  });
                } else {
                  _this.$alert(res.data.message || "添加失败", "系统提示", {
                    confirmButtonText: "确定",
                    callback: action => {}
                  });
                }
              })
              .catch(err => {
                _this.loading = false;
                _this.$alert(err, "系统提示", {
                  confirmButtonText: "确定",
                  callback: action => {}
                });
              });
          }
        }
      });
    },
    // 修改
    handleUpdate() {
      let _this = this;
      _this.$refs.formRef.validate(validate => {
        if (validate) {
          _this.loading = true;
          if (this.$store.state.user.authority === 0) {
            // 用户
            putMember(_this.formData.id, _this.formData)
              .then(res => {
                _this.loading = false;
                if (res.data.code === 200) {
                  _this.$alert("修改成功", "系统消息", {
                    confirmButtonText: "确定",
                    callback: action => {
                      _this.visible = false;
                      _this.$emit("getList");
                    }
                  });
                } else {
                  _this.$alert(res.data.message || "修改失败", "系统消息", {
                    confirmButtonText: "确定",
                    callback: action => {}
                  });
                }
              })
              .catch(err => {
                _this.loading = false;
                _this.$alert(err, "系统消息", {
                  confirmButtonText: "确定",
                  callback: action => {}
                });
              });
          } else {
            // 管理员
            putMemberAdmin(_this.formData.id, _this.formData)
              .then(res => {
                _this.loading = false;
                if (res.data.code === 200) {
                  _this.$alert("修改成功", "系统消息", {
                    confirmButtonText: "确定",
                    callback: action => {
                      _this.visible = false;
                      _this.$emit("getList");
                    }
                  });
                } else {
                  _this.$alert(res.data.message || "修改失败", "系统消息", {
                    confirmButtonText: "确定",
                    callback: action => {}
                  });
                }
              })
              .catch(err => {
                _this.loading = false;
                _this.$alert(err, "系统消息", {
                  confirmButtonText: "确定",
                  callback: action => {}
                });
              });
          }
        }
      });
    },
    // 关闭前的操作
    handleClose() {
      this.visible = false;
    },
    // 验证身份证
    validateIdcard() {
      this.$refs.formRef.validateField("cardId", errorMessage => {
        console.log(errorMessage);
      });
    }
  },
  components: {}
};
</script>

<style scoped>
.common-applicants__edit >>> .el-dialog {
  width: 550px;
  max-width: 90%;
}
.input-with-select >>> .el-input-group__prepend {
  background-color: #fff;
}
</style>
