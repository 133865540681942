<template>
  <div class="common" v-loading="loading">
    <div class="container-1200">
      <div class="common-info">
        <el-table class="common-table" :data="list" stripe border max-height="400px">
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column prop="name" label="姓名" width="100"></el-table-column>
          <!-- <el-table-column prop="certificate" label="证件类型" width="120"></el-table-column> -->
          <el-table-column prop="cardId" label="证件号码" min-width="200"></el-table-column>
          <el-table-column prop="sex" label="性别" width="70"></el-table-column>
          <el-table-column prop="birth" label="出生日期" width="120"></el-table-column>
          <!-- <el-table-column prop="group" label="竞赛组别" width="100"></el-table-column> -->
          <el-table-column align="center" label="操作" width="150">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row.id)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="add-btn">
          <el-button type="primary" @click="handleAdd">新增运动员信息</el-button>
        </div>

        <div class="step-btn-group">
          <el-button type="primary" @click="prevStep()">上一步</el-button>
          <el-button type="primary" @click="nextStep()">下一步</el-button>
        </div>
      </div>
    </div>
    <ParticipantEdit ref="participantEditRef" @getList="getList" />
  </div>
</template>

<script>
import { getMembers, getMembersAdmin, delMember, delMemberAdmin } from '@/api/index.js'
import ParticipantEdit from './ParticipantEdit.vue'
export default {
  name: 'setCommonInfo',
  components: {
    ParticipantEdit
  },
  props: {

  },
  data () {
    return {
      list: [],
      loading: true

    };
  },
  computed: {
    step () {
      if (this.$store.state.user.authority === 1) {
        return this.$store.state.project.stepArr.slice(0, -1)
      } else {
        return this.$store.state.project.stepArr
      }
    }
  },
  created () {
    if (this.step[this.step.length - 1].path !== this.$route.fullPath && this.$store.state.project.status !== 0 && this.$store.state.user.authority === 0) {
      this.$router.push(this.step[this.step.length - 1].path)
    }

  },
  mounted () {
    this.getList()
  },
  watch: {

  },
  methods: {
    // 常用人信息编辑
    handleEdit (info) {
      this.$refs.participantEditRef.show(info)
    },
    // 删除
    handleDelete (id) {
      const _this = this
      _this.$confirm('是否删除该信息？', '系统消息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        _this.loading = true
        if (this.$store.state.user.authority === 0) {
          // 用户
          delMember(id).then(res => {
            _this.loading = false
            if (res.data.code === 200) {
              _this.$message({
                message: '删除成功',
                type: 'success'
              });
              _this.visible = false
              this.getList()
            } else {
              _this.$alert(res.data.message || '删除失败', '系统提示', {
                confirmButtonText: '确定',
                callback: action => {

                }
              });
            }
          }).catch(err => {
            _this.loading = false
            _this.$message.error(err || '删除失败');
          })
        } else {
          // 管理员
          delMemberAdmin(id).then(res => {
            _this.loading = false
            if (res.data.code === 200) {
              _this.$message({
                message: '删除成功',
                type: 'success'
              });
              _this.visible = false
              this.getList()
            } else {
              _this.$alert(res.data.message || '删除失败', '系统提示', {
                confirmButtonText: '确定',
                callback: action => {

                }
              });
            }
          }).catch(err => {
            _this.loading = false
            _this.$message.error(err || '删除失败');
          })
        }


      }).catch(() => {
        console.log('取消删除')
      });

    },
    // 添加常用运动员信息
    handleAdd () {
      // if (this.list.length >= 12) {
      //   this.$alert('每支参赛队伍报名人数不能超 12 人', '系统提示', {});
      //   return
      // }
      this.$refs.participantEditRef.show()
    },
    // 获取常用人信息
    getList () {
      this.loading = true
      if (this.$store.state.user.authority === 0) {
        // 用户
        getMembers({ userId: this.$store.state.user.id }).then(res => {
          this.list = res.data.list
          this.$store.commit('SET_STATUS', res.data.status)
          this.$store.commit('SET_MEMBERS', res.data.list)
          this.loading = false
        }).catch(err => {
          this.loading = false
          console.error(err)
        })
      } else {
        // 管理员
        getMembersAdmin({ userId: this.$store.state.user.id }).then(res => {
          this.list = res.data.list
          this.$store.commit('SET_STATUS', res.data.status)
          this.$store.commit('SET_MEMBERS', res.data.list)
          this.loading = false
        }).catch(err => {
          this.loading = false
          console.error(err)
        })
      }

    },
    prevStep () {
      this.$store.dispatch('STEP_PREV', { router: this.$router, route: this.$route })
    },
    nextStep () {
      this.$store.dispatch('STEP_NEXT', { router: this.$router, route: this.$route })
      // if (this.list.length > 2) {
      //   this.$store.dispatch('STEP_NEXT', { router: this.$router, route: this.$route })
      // } else {
      //   this.$message({
      //     type: 'error',
      //     message: '至少新增三位运动员。'
      //   })
      // }
    }
  }


};
</script>

<style scoped lang="scss">
.common {
  padding: 20px 30px 0;
  width: 100%;
  margin: 0 auto;
}
.common-table {
  width: 100%;
  border-bottom: 0;
  margin: 0 0 50px;
}
.description {
  margin-top: 10px;
}
.common-info-table {
  width: 100%;
  max-width: 800px;
  margin: 30px auto;
  border-collapse: collapse;
  line-height: 2.5;
  td {
    padding: 10px 5px;

    .el-form-item {
      margin-bottom: 0px;
    }
    .el-input {
      border: none;
    }
  }
}
.add-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
